
import { useNavigate, useParams } from 'react-router-dom';
import './CreateEvent.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TicketsTable from '../../components/tickets-table/TicketsTable';
import { fetchWithCredentials, parseDateString, postWithCredentials, revertDateToString } from '../../utils/utils';
import CreateEventStepper from './CreateEventStepper';
import Datepicker from "tailwind-datepicker-react"
import NavbarMobile from '../../components/navbar/NavbarMobile';

const options = {
	title: "",
	autoHide: true,
	todayBtn: false,
	clearBtn: true,
	clearBtnText: "Clear",
	maxDate: new Date("2030-01-01"),
	minDate: new Date("1950-01-01"),
	theme: {
		background: "text-white bg-white",
		todayBtn: "",
		clearBtn: "",
		icons: "",
		text: "",
		disabledText: "bg-red-300",
		input: "",
		inputIcon: "",
		selected: "",
	},
	icons: {
		// () => ReactElement | JSX.Element
		prev: () => <span>Previous</span>,
		next: () => <span>Next</span>,
	},
	datepickerClassNames: "top-12",
	defaultDate: new Date(),
	language: "en",
	disabledDates: [],
	weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
	inputNameProp: "date",
	inputIdProp: "date",
	inputPlaceholderProp: "Select Date",
	inputDateFormatProp: {
		day: "numeric",
		month: "long",
		year: "numeric",
        hour: "numeric",
        minute: "numeric"
	}
}

const CreateEvent = () => {
    const [editMode, setEditMode] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [step, setStep] = useState(0)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.id) {
          setEditMode(true);
          getEvent(params.id);
        }
    }, [params])

    const getEvent = (id) => {
        fetchWithCredentials(`/api/whoppa/getEvent/${id}`, {})
        .then(response => {
            const event = response.content;
            setTickets(event.tickets);
            
            document.getElementById("name").value = event.name;
            document.getElementById("description").value = event.description;
            document.getElementById("location").value = event.location;
            setStartDate(new Date(event.startDate))
            setEndDate(new Date(event.endDate))
        })
    }

    const createEvent = (e) => {
        e.preventDefault();
        const name = document.getElementById("name").value;
        const description = document.getElementById("description").value;
        const location = document.getElementById("location").value;;
        
        postWithCredentials(`/api/whoppa/${editMode ? ('saveEvent/' + params.id) : 'createEvent'}`, {
            body: JSON.stringify({
                name,
                description,
                location,
                startDate: parseDateString(startDate.toDateString()),
                endDate: parseDateString(endDate.toDateString()),
                tickets
            })
        })
        .then(response => {
            navigate('/dashboard/events')
        });
    }

    const addTicket = (e) => {
        e.preventDefault();

        const name = document.getElementById("ticketName").value;
        const price = document.getElementById("ticketPrice").value;
        const amount = document.getElementById("ticketAmount").value;
        const publicTicket = document.getElementById("ticketPublic").checked;

        let tempTickets = [...tickets];

        tempTickets.push({
            name,
            price,
            amount,
            public: publicTicket
        });

        setTickets(tempTickets);
    }

    const deleteTicket = (ticket) => {
        let tempTickets = [...tickets];

        tempTickets = tempTickets.filter(x => x._id !== ticket._id)

        setTickets(tempTickets);
    }

    const handleClose = (state) => {
		setShow(state)
	}

    const handleClose2 = (state) => {
		setShow2(state)
	}

    const handleNextStep = (e) => {
        e.preventDefault();
        let temp = step + 1;
        setStep(temp);
    }

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                <div className='flex-grow p-4 sm:p-12'>
                    <form className={step === 0 ? '' : 'hidden'}>
                    <div className="space-y-6 sm:space-y-8">
                        <div>
                            <h2 className="text-2xl font-semibold leading-6 text-gray-900">Create Event</h2>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                Create a new event.
                            </p>
                        </div>

                        <CreateEventStepper steps={[
                        { id: '0', name: 'Event Details', href: '#' },
                        { id: '1', name: 'Tickets', href: '#' },
                        { id: '2', name: 'Preview', href: '#' },
                        ]} index={step} />
                
                        <div>
                        <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Event Name
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="name"
                                id="name"
                                autoComplete=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Event Description
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="description"
                                id="description"
                                autoComplete=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Event Location
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="location"
                                id="location"
                                autoComplete=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="startDate" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Event Start Date
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <Datepicker options={{...options, inputIdProp: 'startDate'}} value={startDate} onChange={setStartDate} show={show} setShow={handleClose} classNames="block w-full py-1.5 text-gray-900 ring-gray-300 placeholder:text-gray-400 sm:max-w-xs sm:text-sm sm:leading-6" />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="endDate" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Event End Date
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <Datepicker options={{...options, inputIdProp: 'endDate'}} value={endDate} onChange={setEndDate} show={show2} setShow={handleClose2} classNames="block w-full py-1.5 text-gray-900 ring-gray-300 placeholder:text-gray-400 sm:max-w-xs sm:text-sm sm:leading-6" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={(e) => handleNextStep(e)}
                        >
                        Next
                        </button>
                    </div>
                    </form>

                    {step === 1 && <form>
                    <div className="space-y-6 sm:space-y-8">
                        <div>
                            <h2 className="text-2xl font-semibold leading-6 text-gray-900">Create Event</h2>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                Create a new event.
                            </p>
                        </div>

                        <CreateEventStepper steps={[
                        { id: '0', name: 'Event Details', href: '#' },
                        { id: '1', name: 'Tickets', href: '#' },
                        { id: '2', name: 'Preview', href: '#' },
                        ]} index={step} />
                
                        <div>
                        <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="ticketName" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Ticket Name
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="ticketName"
                                id="ticketName"
                                autoComplete=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="ticketPrice" className="block text-sm font-medium leading-6 text-gray-900">
                                Ticket Price
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0 relative rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span className="text-gray-500 sm:text-sm">€</span>
                                </div>
                                <input
                                type="text"
                                name="ticketPrice"
                                id="ticketPrice"
                                className="block w-full sm:max-w-xs rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                        EUR
                                    </span>
                                </div>
                            </div>
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="ticketAmount" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Ticket Amount
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="number"
                                name="ticketAmount"
                                id="ticketAmount"
                                autoComplete=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <fieldset>
                        <legend className="sr-only">Public Ticket</legend>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
                            <div className="text-sm leading-6 text-gray-900" aria-hidden="true">
                            Public Ticket
                            </div>
                            <div className="mt-4 sm:col-span-2 sm:mt-0">
                            <div className="max-w-lg space-y-6">
                                <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                    id="ticketPublic"
                                    name="ticketPublic"
                                    type="checkbox"
                                    defaultChecked="true"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="ticketPublic" className="font-medium text-gray-900">
                                    Public Ticket
                                    </label>
                                    <p className="mt-1 text-gray-600">Public tickets are visible to everyone private are hidden.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </fieldset>
                        </div>
                        </div>

                        <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={(e) => addTicket(e)}
                        >
                        Add Ticket
                        </button>
                    </div>

                    {tickets.length > 0 && <TicketsTable tickets={tickets} onDelete={deleteTicket}/>}
                
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={(e) => createEvent(e)}
                        >
                        {editMode ? 'Save Event' : 'Create Event'}
                        </button>
                    </div>
                    </form>} 
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default CreateEvent;