
import { useNavigate } from 'react-router-dom';
import './NoMatch.css';
import { useEffect } from 'react';

const NoMatch = () => {
    let navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/');
    }

    useEffect(() => {
        navigate('/663b816e16d74d9b8553abb2')
    }, [])

    return (
        <div className='flex flex-col justify-center items-center w-full h-dvh'>
            <h1 className='text-8xl font-bold'>404</h1>
            <h2 className='text-4xl'>Not Found</h2>
        </div>
    );
}

export default NoMatch;