
import { useNavigate, useParams } from 'react-router-dom';
import './AddGuestList.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { postWithCredentials } from '../../utils/utils';
import NavbarMobile from '../../components/navbar/NavbarMobile';

const AddGuestList = () => {
    const navigate = useNavigate();

    const addGuestList = (e) => {
        e.preventDefault();
        const name = document.getElementById("name").value;
        const email = document.getElementById("email").value;
        
        postWithCredentials(`/api/whoppa/addGuestList`, {
            body: JSON.stringify({
                name,
                email,
            })
        })
        .then(response => {
            navigate('/dashboard/guestlist')
        });
    }
    


    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                <div className='flex-grow p-4 sm:p-12'>
                    <form>
                    <div className="space-y-6 sm:space-y-8">
                        <div>
                            <h2 className="text-2xl font-semibold leading-6 text-gray-900">Add Guest List</h2>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                Add a customer to your guestlist.
                            </p>
                        </div>

                        <div>
                        <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Name
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="name"
                                id="name"
                                autoComplete="name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Email
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={(e) => addGuestList(e)}
                        >
                        Save
                        </button>
                    </div>
                    </form>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default AddGuestList;