
import { useNavigate } from 'react-router-dom';
import './Tables.css';

const Tables = () => {
    const navigate = useNavigate();

    return (
        <div className="tables" onClick={() => navigate('/dashboard')}>
            
        </div>
    );
}

export default Tables;