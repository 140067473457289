
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './Dashboard.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { setEvents, setOrders } from '../../redux/infoSlice';
import { fetchWithCredentials } from '../../utils/utils';
import NavbarMobile from '../../components/navbar/NavbarMobile';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
};

const getLast7Days = () => {
    const today = new Date();
    const last7Days = [];
  
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
  
      const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
      const year = (date.getFullYear()).toString(); // Month is 0-indexed
  
      last7Days.push({ day, month, year });
    }
  
    return last7Days;
}

const Dashboard = () => {
    const orders = useSelector((state) => state.infoReducer.orders)
    const events = useSelector((state) => state.infoReducer.events)
    const [data, setData] = useState({
        labels: getLast7Days().map(x => `${x.day}-${x.month}`),
        datasets: [
          {
            label: 'Tickets',
            data: [0, 0, 0, 0, 0, 0, 0],
            borderColor: 'rgb(79, 70, 229)',
            backgroundColor: 'rgba(79, 70, 229)',
          },
        ],
    })
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getOrders()
        getEvents()
    }, [])

    const getOrders = () => {
        fetchWithCredentials(`/api/whoppa/getOrders`, {})
        .then(response => {
            dispatch(setOrders(response.content))
            setOrders(response.content)

            const countItemsByDate = {};

            const originalDates = getLast7Days().map(x => `${x.day}-${x.month}`);
            response.content.forEach((obj) => {
                const createdOnDate = new Date(obj.createdOn);
                const day = createdOnDate.getDate().toString().padStart(2, '0'); // Ensure two digits
                const month = (createdOnDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
                const mappedDate = `${day}-${month}`;
                
                // Check if the date is in the originalDates array
                if (originalDates.includes(mappedDate)) {
                    // Increment the count for the date
                    countItemsByDate[mappedDate] = (countItemsByDate[mappedDate] || 0) + obj.tickets.length;
                }
            });

            const countsArray = originalDates.map(date => countItemsByDate[date] || 0);
            handleUpdateData(countsArray);
        })
    }

    const getEvents = () => {
        fetchWithCredentials(`/api/whoppa/getEvents`, {})
        .then(response => {
            dispatch(setEvents(response.content))
            setEvents(response.content)
        })
    }

    const handleUpdateData = (newData) => {
        setData({
            ...data,
            datasets: [{
                ...data.datasets[0],
                data: newData
            }]
        })
    }

    const daysLeftUntil = (dateString) => {
        const targetDate = new Date(dateString);
        const currentDate = new Date();
        const differenceMs = targetDate - currentDate;
        const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
        
        return daysLeft;
    }

    return (
        <div className="Events company">
            <div className="EventsWrapper">
                <Navbar />
                <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                    <div className='flex-grow p-4 sm:p-12'>
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center gap-3 mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Home</h3>
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => navigate('/dashboard/events/create')}
                            >
                                New Event
                            </button>
                        </div>

                        <div className="mt-8 flow-root bg-white rounded-lg ring-1 ring-black ring-opacity-5 shadow">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <Bar options={options} data={data} />
                                </div>
                            </div>
                        </div>

                        <div className="mt-10 border-b border-gray-200 pb-5 flex flex-col gap-20 sm:gap-0 sm:flex-row sm:justify-between mb-6">
                            <div>
                                <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Upcoming Events</h3>
                                <div className='w-full flex flex-col'>
                                {events.filter(x => daysLeftUntil(x.startDate) >= 0).map((event) => (
                                    <div className="mt-8 flow-root bg-white rounded-lg ring-1 ring-black ring-opacity-5 shadow">
                                        <div className="overflow-x-auto p-4">
                                            <div className="inline-block min-w-full py-2 align-middle">
                                                <h1 className="text-xl font-bold">{event.name}</h1>
                                                <small className="text-gray-500">From {new Date(event.startDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })} until {new Date(event.endDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</small>

                                                <h4 className="font-bold mt-4">Event Description</h4>
                                                <small>{event.description}</small>

                                                <h4 className="font-bold mt-4">Event Location</h4>
                                                <small>{event.location}</small>

                                                <h4 className="font-bold mt-4">Tickets</h4>
                                                <small>
                                                    {event && event.orders}
                                                    /
                                                    {event && event.tickets && event.tickets.length > 0 && event.tickets.reduce((accumulator, currentItem) => {
                                                        return accumulator + currentItem.amount;
                                                    }, 0)}
                                                </small>

                                                <h4 className="font-bold mt-4">Days Left</h4>
                                                <small>{daysLeftUntil(event.startDate)}</small>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            </div>
                            <div>
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Past Events</h3>
                            <div className='w-full flex flex-col'>
                                {events.filter(x => daysLeftUntil(x.startDate) >= -14 & daysLeftUntil(x.startDate) < 0).map((event) => (
                                    <div className="mt-8 flow-root bg-white rounded-lg ring-1 ring-black ring-opacity-5 shadow">
                                        <div className="overflow-x-auto p-4">
                                            <div className="inline-block min-w-full py-2 align-middle">
                                                <h1 className="text-xl font-bold">{event.name}</h1>
                                                <small className="text-gray-500">From {new Date(event.startDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })} until {new Date(event.endDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</small>

                                                <h4 className="font-bold mt-4">Event Revenue</h4>
                                                <small>€2.510</small>

                                                <h4 className="font-bold mt-4">Paid Tickets</h4>
                                                <small>284</small>

                                                <h4 className="font-bold mt-4">Free Tickets</h4>
                                                <small>14</small>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;