import './OrdersTable.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { generateTicketSummary, postWithCredentials } from '../../utils/utils';

const OrdersTable = ({ orders, refresh }) => {
    const [showDropDown, setShowDropDown] = useState(false);
    const dropDownRef = useRef(null);
    const navigate = useNavigate();

    const handleClick = (index) => {
        setShowDropDown(index);
    }

    const handleOutsideClick = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setShowDropDown(undefined);
        }
      };

    useEffect(() => {
        if (showDropDown) {
          // Add event listener when the popup is visible
          window.addEventListener('click', handleOutsideClick);
        }
    
        // Clean up the event listener when the component unmounts or the popup is closed
        return () => {
          window.removeEventListener('click', handleOutsideClick);
        };
    }, [showDropDown]);

    return (
        <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Event
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Ticket
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {orders.map((order) => (
                    <tr key={order.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {order.event?.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{generateTicketSummary(order.tickets)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.firstName} {order.lastName}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Date(order.createdOn).toLocaleDateString('en-US', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${order.status === "Paid" ? 'text-green-700 ring-green-600/20 bg-green-50' : order.status === "Error" ? 'text-red-700 ring-red-600/20 bg-red-50' : 'text-indigo-700 ring-indigo-600/20 bg-indigo-50'}`}>
                            {order.status}
                        </span>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        {order.refund && <button type="button" className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Refund</button>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
}

export default OrdersTable;
