export default function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="pb-12 md:pb-20">
            <h2 className="h2 font-hkgrotesk">FAQs</h2>
          </div>
          {/* Columns */}
          <div className="md:flex md:space-x-12 space-y-8 md:space-y-0">
            {/* Column */}
            <div className="w-full md:w-1/2 space-y-8">
              {/* Item */}
              <div className="space-y-2">
                <h4 className="text-xl font-hkgrotesk font-medium">Can I use WhoppaTix for my event?</h4>
                <p className="text-slate-500">
                    There is no minimum or maximum number of tickets you need to sell to be eligible for a ticket shop. Whether you're organizing a small, intimate event or a large-scale festival, you can always come to us.<br></br><br></br>

                    We understand that not all events can have the same ticket price. That's why we allow you to offer tickets starting from a minimum amount of just €0.50.
                </p>
              </div>
              {/* Item */}
              <div className="space-y-2">
                <h4 className="text-xl font-hkgrotesk font-medium">Can I get an overview of my events?</h4>
                <p className="text-slate-500">
                You can easily get a detailed overview of your event. Our user-friendly dashboard provides all the necessary information and statistics to manage your event effectively. Discover the number of tickets sold, revenue, and visitor statistics all in one place.<br></br><br></br>

You no longer need to worry about searching for scattered data. With our clear dashboard, you get a comprehensive view of your event's performance. This allows you to make informed decisions, adjust marketing strategies, and elevate your event to the next level.
                </p>
              </div>
            </div>
            {/* Column */}
            <div className="w-full md:w-1/2 space-y-8">
              {/* Item */}
              <div className="space-y-2">
                <h4 className="text-xl font-hkgrotesk font-medium">How can I best promote my event?</h4>
                <p className="text-slate-500">
                We have a great feature, the market tab that allows you to promote your event and generate more ticket sales. With this page, you can create multiple unique marketers with their own custom link. This way, you can see exactly how well your promoters are performing and how many people have purchased tickets through each specific promoter.<br></br><br></br>

                Using this valuable data, you can measure the effectiveness of your promotional efforts and optimize your marketing strategies. Identify which promoters are achieving the most success and reward them for their efforts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}