
import './Marketing.css';
import Navbar from '../../components/navbar/Navbar';
import RefferalsTable from '../../components/refferals-table/RefferalsTable';
import Footer from '../../components/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchWithCredentials } from '../../utils/utils';
import { setMarketers } from '../../redux/infoSlice';
import MarketingOverview from '../../components/marketing-overview/MarketingOverview';
import NavbarMobile from '../../components/navbar/NavbarMobile';

const Marketing = () => {
    const marketers = useSelector((state) => state.infoReducer.marketers)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getMarketers()
    }, [])

    const getMarketers = () => {
        fetchWithCredentials(`/api/whoppa/getMarketers`, {})
        .then(response => {
            console.log(response)
            dispatch(setMarketers(response.content || []))
        })
    }

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                    <div className='flex-grow p-4 sm:p-12'>
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Marketing Team</h3>
                            <div className="mt-3 sm:ml-4 sm:mt-0">
                                <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => navigate('/dashboard/marketing/create')}
                                >
                                Add Marketer
                                </button>
                            </div>
                        </div>
                        <MarketingOverview marketers={marketers} />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Marketing;