export default function Header({ nav = true }) {
  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <a className="block" href="/" aria-label="Cruip">
            <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" id="ehddaehg" className="h-20 css-8aabad">
  <defs id="SvgjsDefs1684"></defs>
  <g fill="#fff" id="SvgjsG1686" transform="translate(25,25) scale(0.5)">
    <path d="M58.623 40.078 l-9.4336 20.039 l-15.059 0 l-0.46875 -16.377 c-2.6074 5.3613 -5.332 10.898 -7.793 16.377 l-15.088 0 l-0.29297 -20.039 l-2.666 0 l-0.43945 -23.936 l15.293 0 l-0.41016 17.314 c2.666 -5.7129 5.4492 -11.572 8.2031 -17.314 l14.092 0 l0 17.314 c2.6367 -5.7129 5.127 -11.572 7.7051 -17.314 l15 0 l-11.309 23.936 l2.666 0 z"></path>
  </g>
</svg>

              {/* <svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient x1="0%" y1="32.443%" x2="104.18%" y2="50%" id="hlogo-a">
                    <stop stopColor="#FFF" stopOpacity=".299" offset="0%" />
                    <stop stopColor="#7587E4" stopOpacity="0" offset="100%" />
                  </linearGradient>
                  <linearGradient x1="18.591%" y1="0%" x2="100%" y2="100%" id="hlogo-b">
                    <stop stopColor="#818CF8" offset="0%" />
                    <stop stopColor="#C7D2FE" offset="100%" />
                  </linearGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <path fill="#3730A3" d="M16 18.5V32l15.999-9.25V9.25z" />
                  <path fill="#4F46E5" d="m0 23 16 9V18.501L0 9.251z" />
                  <path fillOpacity=".64" fill="url(#hlogo-a)" d="M16 13 0 23l16 9 16-9z" />
                  <path fill="url(#hlogo-b)" d="M16 0 0 9.25l16 9.25 15.999-9.25z" />
                </g>
              </svg> */}
            </a>
          </div>
          {/* Desktop navigation */}
          {nav &&
            <nav className="flex grow">
              {/* Desktop sign in links */}
              <ul className="flex grow justify-end flex-wrap items-center">
                <li>
                  <a className="font-medium text-slate-500 hover:text-slate-300 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out" href="/login">Sign in</a>
                </li>
                <li className="ml-3">
                  <a className="btn-sm text-white bg-indigo-600 hover:bg-indigo-700 w-full shadow-sm group" href="/register">
                    Get Started
                  </a>
                </li>
              </ul>
            </nav>
          }
        </div>
      </div>
    </header>
  )
}
