
import { useNavigate } from 'react-router-dom';
import './Events.css';
import EventsTable from '../../components/events-table/EventsTable';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import Footer from '../../components/footer/Footer';
import { setEvents } from '../../redux/infoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWithCredentials, WithAuth } from '../../utils/utils';
import DeleteEventDialog from '../../components/delete-event-dialog/DeleteEventDialog';
import NavbarMobile from '../../components/navbar/NavbarMobile';

const Events = () => {
    const events = useSelector((state) => state.infoReducer.events)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [toDeleteEvent, setToDeleteEvent] = useState(null)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getEvents()
    }, [])

    const getEvents = () => {
        fetchWithCredentials(`/api/whoppa/getEvents`, {})
        .then(response => {
            dispatch(setEvents(response.content))
        })
    }

    const onDelete = (event) => {
        setIsModalOpen(true)
        setToDeleteEvent(event)
    }

    const deleteEvent = () => {
        fetchWithCredentials(`/api/whoppa/deleteEvent/${toDeleteEvent._id}`, {})
        .then(response => {
            getEvents();
        })

        setIsModalOpen(false)
        setToDeleteEvent(null)
    }

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                {isModalOpen && <DeleteEventDialog toDelete={toDeleteEvent} deleteEvent={deleteEvent} setIsModalOpen={setIsModalOpen} />}

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                    <div className='flex-grow p-4 sm:p-12'>
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Events</h3>
                            <div className="mt-3 sm:ml-4 sm:mt-0">
                                <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => navigate('/dashboard/events/create')}
                                >
                                Add Event
                                </button>
                            </div>
                        </div>
                        {events && events.length > 0 && <EventsTable events={events.filter(event => new Date(event.startDate) >= new Date())} onDelete={onDelete} />}
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6 mt-10">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Past Events</h3>
                        </div>
                        {events && events.length > 0 && <EventsTable events={events.filter(event => new Date(event.startDate) < new Date())} onDelete={onDelete} />}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default WithAuth(Events);