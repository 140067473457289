
import './Company.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect } from 'react';
import Footer from '../../components/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount } from '../../redux/infoSlice';
import { toast } from 'react-toastify';
import { fetchWithCredentials, postWithCredentials } from '../../utils/utils';
import NavbarMobile from '../../components/navbar/NavbarMobile';

const Company = () => {
    const account = useSelector((state) => state.infoReducer.account)
    const dispatch = useDispatch();

    useEffect(() => {
        getProfile();
    }, [])

    const getProfile = () => {
        fetchWithCredentials(`/api/whoppa/getProfile/${account._id}`, {})
        .then(response => {
            dispatch(setAccount({
                ...account,
                ...response.content
            }))
        })
    }

    const onChange = (e, prop) => {
        dispatch(setAccount({
            ...account,
            [prop]: e.target.value
        }))
    }

    const save = (e) => {     
        e.preventDefault();   
        postWithCredentials(`/api/whoppa/updateAccount/${account._id}`, {
            body: JSON.stringify(account)
        })
        .then(response => {
            toast.success('Changed company info!')
        });
    }
    
    return (
        <div className="Events company">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                <div className='flex-grow p-4 sm:p-12'>
                    <form>
                    <div className="space-y-6 sm:space-y-8">
                        <div>
                            <h2 className="text-2xl font-semibold leading-6 text-gray-900">Company Settings</h2>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                This is your companies settings page.
                            </p>
                        </div>
                
                        <div>
                        <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Company Name
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="company"
                                id="company"
                                autoComplete="company"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                value={account.company}
                                onChange={(e) => onChange(e, 'company')}
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Country
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="country"
                                id="country"
                                autoComplete="country"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                value={account.country}
                                onChange={(e) => onChange(e, 'country')}
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="vat" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                VAT Number
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="vat"
                                id="vat"
                                autoComplete="vat"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                value={account.VAT}
                                onChange={(e) => onChange(e, 'VAT')}
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="accountHolder" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Account Holder
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="accountHolder"
                                id="accountHolder"
                                autoComplete="accountHolder"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                value={account.accountHolder}
                                onChange={(e) => onChange(e, 'accountHolder')}
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="iban" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                IBAN
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="iban"
                                id="iban"
                                autoComplete="iban"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                value={account.IBAN}
                                onChange={(e) => onChange(e, 'IBAN')}
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="bic" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                BIC
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="bic"
                                id="bic"
                                autoComplete="bic"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                value={account.BIC}
                                onChange={(e) => onChange(e, 'BIC')}
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="reference" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Payout Reference
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="reference"
                                id="reference"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                value={account.reference}
                                onChange={(e) => onChange(e, 'reference')}
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="currency" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Currency
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <select
                                    id="currency"
                                    name="currency"
                                    className="block w-full rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    value={account.currency}
                                    onChange={(e) => onChange(e, 'currency')}
                                >
                                    <option value="eur">EUR</option>
                                    <option value="usd">USD</option>
                                    <option value="gbp">GBP</option>
                                </select>
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="payouts" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Payouts
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <select
                                    id="payouts"
                                    name="payouts"
                                    autoComplete="payouts"
                                    className="block w-full rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    value={account.payouts}
                                    onChange={(e) => onChange(e, 'payouts')}
                                >
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="manual">Manual</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={(e) => save(e)}
                        >
                        Save
                        </button>
                    </div>
                    </form>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Company;