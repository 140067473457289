const IconClose = ({ size = 18, className = '' }) => {
    return (
        <svg
            className={className}
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 25 25"
        >
            <g transform="translate(-1627, -118)" strokeWidth="3">
                <g transform="translate(1628, 119)">
                    <line
                        x1="0.5"
                        y1="0.5"
                        x2="22.5"
                        y2="22.5"
                        id="Line-4"
                    ></line>
                    <line
                        x1="0.5"
                        y1="0.5"
                        x2="22.5"
                        y2="22.5"
                        id="Line-4"
                        transform="translate(11.5, 11.5) scale(-1, 1) translate(-11.5, -11.5)"
                    ></line>
                </g>
            </g>
        </svg>
    );
};

export default IconClose;
