import moment from 'moment';
import { generateTicketSummary } from '../../utils/utils';
import './Scan.css';
import { FaCheckCircle, FaPlusCircle } from 'react-icons/fa';
import { useEffect, useState } from 'react';

const Scan = ({ reset, scanResult }) => {
  const [currentScan, setCurrentScan] = useState(scanResult);

  const getSecondsDifference = (date1, date2) => {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
        throw new Error('Both arguments must be Date objects');
    }

    const differenceInMilliseconds = Math.abs(date1 - date2);
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

    return differenceInSeconds;
}

  useEffect(() => {
    if (currentScan.scanned && getSecondsDifference(new Date(), new Date(scanResult.checkedInOn)) < 60) {
      setCurrentScan({
        ...scanResult,
        scanned: false,
      })
    }
  }, [])

  return (
    <div className="scan" onClick={() => reset()}>
      <div className="tickets">
        <div className="ticket-qr">
          {currentScan.scanned ? <FaPlusCircle size="128" id="plus" /> : <FaCheckCircle size="128" id="check" />}
          <h4>{currentScan.scanned ? 'Ticket Already Scanned' : 'Ticket Scanned Succesfully.'}</h4>
          <p>{currentScan.firstName} {currentScan.lastName}</p>
          {currentScan.scanned && <p>{moment(currentScan.checkedInOn).format('D/M hh:mm A')}</p>}
          <div>
            {generateTicketSummary(currentScan.tickets)}
          </div>
        </div>

      </div>
    </div>
  );
}

export default Scan;
