
import { useNavigate } from 'react-router-dom';
import './BlackList.css';
import EventsTable from '../../components/events-table/EventsTable';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import Footer from '../../components/footer/Footer';
import GuestListTable from '../../components/guestlist-table/GuestListTable';
import { fetchWithCredentials } from '../../utils/utils';
import NavbarMobile from '../../components/navbar/NavbarMobile';

const BlackList = () => {
    const [list, setList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [toDeleteGuest, setToDeleteGuest] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        getBlacklist()
    }, [])

    const getBlacklist = () => {
        fetchWithCredentials(`/api/whoppa/getBlacklist`, {})
        .then(response => {
            setList(response.content.list)
        })
    }

    const onDelete = (event) => {
        setIsModalOpen(true)
        setToDeleteGuest(event)
    }

    const deleteGuest = () => {
        fetchWithCredentials(`/api/whoppa/deleteBlackList/${toDeleteGuest.name}`, {})
        .then(response => {
            getBlacklist();
        })

        setIsModalOpen(false)
        setToDeleteGuest(null)
    }

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                {isModalOpen && <div className="eventDeleteModalWrapper">
                    <div className="eventDeleteModal">
                        <div className='eventDeleteModalContent'>
                            <h1>Are you sure</h1>
                            <h4>You want to delete {toDeleteGuest.name} ?</h4>
                            <FaTrash size="128" id='trash' />
                        </div>
                        <div className='eventDeleteModalButtons'>
                            <button onClick={() => setIsModalOpen(false)} >Cancel</button>
                            <button onClick={() => deleteGuest()} style={{ backgroundColor: 'red' }}>Delete</button>
                        </div>
                    </div>
                </div>}

                <div className='events-content'>
                    <div className='events-list'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                            <h2>Black List</h2>
                            <button style={{ height: '30px' }} onClick={() => navigate('/dashboard/blacklist/create')}>Add Guest</button>
                        </div>
                        {list && list.length > 0 && <GuestListTable list={list} onDelete={onDelete} />}
                    </div>

                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default BlackList;