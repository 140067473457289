
import { useNavigate, useParams } from 'react-router-dom';
import './CreateUser.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { fetchWithCredentials, postWithCredentials } from '../../utils/utils';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount } from '../../redux/infoSlice';
import NavbarMobile from '../../components/navbar/NavbarMobile';

const CreateUser = () => {
    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const account = useSelector((state) => state.infoReducer.account)
    const [createNew, setCreateNew] = useState(false)
    const [newAccount, setNewAccount] = useState({ permissions: { payouts: false } })
    const dispatch = useDispatch();

    useEffect(() => {
        if (params.id) {
          setEditMode(true);
          getUser(params.id);
        }
    }, [params])

    const getUser = (id) => {
        fetchWithCredentials(`/api/whoppa/getProfile/${id}`, {})
        .then(response => {
            const user = response.content;
            
            document.getElementById("name").value = user.name;
            document.getElementById("email").value = user.email;
            document.getElementById("password").value = user.password;
            document.getElementById("payouts").value = user.permissions.payouts;
        })
    }

    const save = (e) => {   
        e.preventDefault()
        postWithCredentials(`/api/whoppa/updateAccount/${account._id}`, {
            body: JSON.stringify(account)
        })
        .then(response => {
            toast.success('Changed sub account info!')
            navigate('/dashboard/users')
        });
    }

    const create = (e) => {
        e.preventDefault()
        const name = document.getElementById("name").value;
        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;
        const payouts = document.getElementById("payouts").checked;

        if (!name) {
            toast.error('Account Name Required!')
            return
        }
        if (!email) {
            toast.error('Account Email Required!')
            return
        }
        if (!password) {
            toast.error('Account Password Required!')
            return
        }
        postWithCredentials(`/api/whoppa/registerSubAccount`, {
            body: JSON.stringify({
                name,
                email,
                password,
                permissions: {
                    payouts: payouts
                },
                parent: account._id
            })
        })
        .then(response => {
            toast.success('Created Sub Account!')
            navigate('/dashboard/users')
        }).catch((err) => {
            console.log(err)
        });
    }

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                <div className='flex-grow p-4 sm:p-12'>
                    <form>
                    <div className="space-y-6 sm:space-y-8">
                        <div>
                            <h2 className="text-2xl font-semibold leading-6 text-gray-900">Sub Profile</h2>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                               This sub profile will be able to acces parts of your dashboard based on permissions.
                            </p>
                        </div>
                
                        <div>
                        <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Full name
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="name"
                                id="name"
                                autoComplete="name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>
                
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Email address
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Password
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                id="password"
                                name="password"
                                type="text"
                                autoComplete="password"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <fieldset>
                        <legend className="sr-only">Ambassador</legend>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
                            <div className="text-sm leading-6 text-gray-900" aria-hidden="true">
                            Ambassador
                            </div>
                            <div className="mt-4 sm:col-span-2 sm:mt-0">
                            <div className="max-w-lg space-y-6">
                                <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                    id="payouts"
                                    name="payouts"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="payouts" className="font-medium text-gray-900">
                                    Payouts
                                    </label>
                                    <p className="mt-1 text-gray-600">Financial area of company.</p>
                                </div>
                                </div>

                                <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                    id="other"
                                    name="other"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="other" className="font-medium text-gray-900">
                                    Other things
                                    </label>
                                    <p className="mt-1 text-gray-600">Other permissions</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </fieldset>
                        </div>
                        </div>
                    </div>
                
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => navigate('/dashboard/users')}>
                        Cancel
                        </button>
                        <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={(e) => editMode ? save(e) : create(e)}
                        >
                        {editMode ? 'Save' : 'Create'}
                        </button>
                    </div>
                    </form>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default CreateUser;