export default function Hero() {
  return (
    <section className="relative">
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-40">
          {/* Hero content */}
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="h1 font-hkgrotesk mb-6" data-aos="fade-up">
              WhoppaTix
            </h1>
            <p className="text-xl text-slate-500 mb-10" data-aos="fade-up" data-aos-delay="100">
              Your Journey to Amazing Events Starts with WhoppaTix.
            </p>
            <div
              className="max-w-xs mx-auto sm:max-w-none sm:inline-flex sm:justify-center space-y-4 sm:space-y-0 sm:space-x-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div>
                {/* TODO NAV: href to register */}
                <a className="btn text-white bg-indigo-600 hover:bg-indigo-700 w-full shadow-sm group" href="/contact">
                  Get Started {' '}

                </a>
              </div>
              {/* <div>
                <a className="btn text-slate-300 bg-slate-700 hover:bg-slate-600 border-slate-600 w-full shadow-sm" href="#0">
                  Read Docs
                </a>
              </div> */}
            </div>
          </div>
          {/* Hero image */}
          <div className="pt-16 md:pt-20" data-aos="fade-up" data-aos-delay="300">
            <img className="mx-auto rounded shadow-2xl" src={`${process.env.PUBLIC_URL}/hero-image3.png`} width={920} height={518} alt="Hero" />
          </div>
        </div>
      </div>
    </section>
  )
}