
import { useNavigate } from 'react-router-dom';
import './GuestList.css';
import EventsTable from '../../components/events-table/EventsTable';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import { FaMailBulk, FaTicketAlt, FaTrash } from 'react-icons/fa';
import Footer from '../../components/footer/Footer';
import GuestListTable from '../../components/guestlist-table/GuestListTable';
import { fetchWithCredentials, postWithCredentials } from '../../utils/utils';
import DeleteGuestDialog from '../../components/delete-guest-dialog/DeleteGuestDialog';
import SendTicketDialog from '../../components/send-ticket-dialog/SendTicketDialog';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setOrders } from '../../redux/infoSlice';
import OrdersTable from '../../components/orders-table/OrdersTable';
import NavbarMobile from '../../components/navbar/NavbarMobile';

const GuestList = () => {
    const orders = useSelector((state) => state.infoReducer.orders)
    const [guestList, setGuestList] = useState([])
    const [blackList, setBlackList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isTicketModalOpen, setIsTicketModalOpen] = useState(false)
    const [toSendGuest, setToSendGuest] = useState(null)
    const [toDeleteGuest, setToDeleteGuest] = useState(null)
    const [toDeleteBlack, setToDeleteBlack] = useState(null)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getGuestlist()
        getBlackList()
        getOrders()
    }, [])

    const getGuestlist = () => {
        fetchWithCredentials(`/api/whoppa/getGuestlist`, {})
        .then(response => {
            setGuestList(response.content.list);
        })
    }

    const getBlackList = () => {
        fetchWithCredentials(`/api/whoppa/getBlackList`, {})
        .then(response => {
            setBlackList(response.content.list);
        })
    }

    const getOrders = () => {
        fetchWithCredentials(`/api/whoppa/getOrders`, {})
        .then(response => {
            dispatch(setOrders(response.content))
            setOrders(response.content)
        })
    }

    const onSendTicket = (event) => {
        setIsTicketModalOpen(true)
        setToSendGuest(event)
    }

    const onDelete = (event) => {
        setIsModalOpen(true)
        setToDeleteGuest(event)
    }

    const onDeleteBlack = (event) => {
        setIsModalOpen(true)
        setToDeleteBlack(event)
    }

    const deleteGuest = () => {
        const guest = toDeleteGuest || toDeleteBlack;
        fetchWithCredentials(`/api/whoppa/delete${toDeleteGuest ? 'Guest' : 'Black'}List/${guest.email}`, {})
        .then(response => {
            getGuestlist();
            getBlackList();
        })

        setIsModalOpen(false)
        setToDeleteGuest(null)
        setToDeleteBlack(null)
    }

    const sendTicket = () => {
        const guest = toSendGuest;
        postWithCredentials(`/api/whoppa/sendFreeTickets`, {
            body: JSON.stringify({
                name: guest.name,
                email: guest.email
            })
        })
        .then(response => {

        })

        setIsTicketModalOpen(false)
        setToSendGuest(null)
    }

    return (
        <div className="Events dark">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                {isModalOpen && <DeleteGuestDialog deleteGuest={deleteGuest} setIsModalOpen={setIsModalOpen} />}

                {isTicketModalOpen && <SendTicketDialog sendTicket={sendTicket} setIsModalOpen={setIsTicketModalOpen} />}

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                    <div className='flex-grow p-4 sm:p-12 pb-0'>
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Guest List</h3>
                            <div className="mt-3 sm:ml-4 sm:mt-0">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={() => navigate('/dashboard/guestlist/create')}
                                >
                                Add Guest
                                </button>
                            </div>
                        </div>
                        
                        {guestList && guestList.length > 0 && <GuestListTable list={guestList} onDelete={onDelete} onSendTicket={onSendTicket} guestList />}
                    </div>

                    <div className='flex-grow p-4 sm:p-12 pb-0'>
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Paid List</h3>
                        </div>
                        
                        {orders && orders.length > 0 && <OrdersTable orders={orders} refresh={getOrders} />}
                    </div>

                    <div className='flex-grow p-4 sm:p-12'>
                    <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                        <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Black List</h3>
                        <div className="mt-3 sm:ml-4 sm:mt-0">
                            <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => navigate('/dashboard/blacklist/create')}
                            >
                            Add Guest
                            </button>
                        </div>
                    </div>
                        
                        {blackList && blackList.length > 0 && <GuestListTable list={blackList} onDelete={onDeleteBlack} guestList={false} />}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default GuestList;