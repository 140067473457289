import { useEffect } from 'react'

// Import Swiper
import Swiper from 'swiper';
import '../../../node_modules/swiper/swiper.min.css';

// Initialize the modules
Swiper.use([Swiper.Autoplay, Swiper.Navigation]);

export default function Features() {

  return (
    <section className="relative">
      {/* Bg illustration */}
      <div className="absolute left-1/2 -translate-x-1/2 pointer-events-none -mt-20" aria-hidden="true">
        <img src={`${process.env.PUBLIC_URL}/features-illustration.svg`} className="max-w-none" width="1440" height="440" alt="Illustration" />
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-hkgrotesk mb-4">Many but easy tools for an advanced and great experience</h2>
            <div className="max-w-2xl mx-auto">
              <p className="text-xl text-slate-500">
                Don't worry we are not as complicated as our competitors but with our tools you can quickly and easily get all the information you need for an amazing event
              </p>
            </div>
          </div>
          {/* Carousel built with Swiper.js [https://swiperjs.com/] */}
          {/* * Custom styles in src/css/additional-styles/theme.scss */}
          <div className="carousel">
            <div className="flex gap-6 overflow-x-scroll hide-scrollbar">
              {/* Carousel items */}
              <div className="flex-shrink-0 relative h-auto w-5/6 md:w-1/3 flex flex-col bg-slate-800 p-6 rounded">
                <img className="mb-3" src={`${process.env.PUBLIC_URL}/features-icon-01.svg`} width={56} height={56} alt="Icon 01" />
                <div className="grow">
                  <div className="font-hkgrotesk font-bold text-xl">Login</div>
                  <div className="text-slate-500 mb-3">
                  Quick/easy and secure login so you can stay up to date and log in wherever you are.
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 relative h-auto w-5/6 md:w-1/3 flex flex-col bg-slate-800 p-6 rounded">
                <img className="mb-3" src={`${process.env.PUBLIC_URL}/features-icon-02.svg`} width={56} height={56} alt="Icon 02" />
                <div className="grow">
                  <div className="font-hkgrotesk font-bold text-xl">Graphs</div>
                  <div className="text-slate-500 mb-3">
                  With our extensive selection of graphs, you can view a detailed report on ticket sales, see how many tickets are still available, and get brief information about who is purchasing the tickets.
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 relative h-auto w-5/6 md:w-1/3 flex flex-col bg-slate-800 p-6 rounded">
                <img className="mb-3" src={`${process.env.PUBLIC_URL}/features-icon-03.svg`} width={56} height={56} alt="Icon 03" />
                <div className="grow">
                  <div className="font-hkgrotesk font-bold text-xl">Marketing Team</div>
                  <div className="text-slate-500 mb-3">
                  Make it easy for yourself by not needing to open various apps or files to find your marketing team. With our tab, you can find extensive data including photos, phone numbers, emails, Instagram profiles, and how many tickets the person has sold in the past. Thanks to our innovative software, you can also call them directly via the site.
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 relative h-auto w-5/6 md:w-1/3 flex flex-col bg-slate-800 p-6 rounded">
                <img className="mb-3" src={`${process.env.PUBLIC_URL}/features-icon-04.svg`} width={56} height={56} alt="Icon 04" />
                <div className="grow">
                  <div className="font-hkgrotesk font-bold text-xl">Guest List</div>
                  <div className="text-slate-500 mb-3">
                  A guest list can be integrated so that VIP members or regular customers automatically receive a free ticket.
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 relative h-auto w-5/6 md:w-1/3 flex flex-col bg-slate-800 p-6 rounded">
                <img className="mb-3" src={`${process.env.PUBLIC_URL}/features-icon-04.svg`} width={56} height={56} alt="Icon 04" />
                <div className="grow">
                  <div className="font-hkgrotesk font-bold text-xl">Blacklist</div>
                  <div className="text-slate-500 mb-3">
                  A blacklist is a list that prevents people from purchasing tickets online when they enter their personal information. Security also has an up-to-date, handy list to enhance the safety of your event.
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 relative h-auto w-5/6 md:w-1/3 flex flex-col bg-slate-800 p-6 rounded">
                <img className="mb-3" src={`${process.env.PUBLIC_URL}/features-icon-04.svg`} width={56} height={56} alt="Icon 04" />
                <div className="grow">
                  <div className="font-hkgrotesk font-bold text-xl">Payment Methods</div>
                  <div className="text-slate-500 mb-3">
                  We offer various payment methods so that customers have a wide selection of ways to purchase tickets online. One of the highlights is the option to pay later through a partnered payment method.
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 relative h-auto w-5/6 md:w-1/3 flex flex-col bg-slate-800 p-6 rounded">
                <img className="mb-3" src={`${process.env.PUBLIC_URL}/features-icon-04.svg`} width={56} height={56} alt="Icon 04" />
                <div className="grow">
                  <div className="font-hkgrotesk font-bold text-xl">User Accessibility</div>
                  <div className="text-slate-500 mb-3">
                  You can choose which individuals have access to certain data on the platform.
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 relative h-auto w-5/6 md:w-1/3 flex flex-col bg-slate-800 p-6 rounded">
                <img className="mb-3" src={`${process.env.PUBLIC_URL}/features-icon-04.svg`} width={56} height={56} alt="Icon 04" />
                <div className="grow">
                  <div className="font-hkgrotesk font-bold text-xl">In-app Scanner</div>
                  <div className="text-slate-500 mb-3">
                  With our QR scanner, customers' tickets can be quickly and securely scanned, and the data is automatically displayed on the tab.
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 relative h-auto w-5/6 md:w-1/3 flex flex-col bg-slate-800 p-6 rounded">
                <img className="mb-3" src={`${process.env.PUBLIC_URL}/features-icon-04.svg`} width={56} height={56} alt="Icon 04" />
                <div className="grow">
                  <div className="font-hkgrotesk font-bold text-xl">3D Map Integration</div>
                  <div className="text-slate-500 mb-3">
                  With our innovative software, customers can visually imagine and check online if a desired VIP table is available in real-time.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Arrows */}
          {/* <div className="flex mt-12 space-x-4 justify-end">
            <button className="carousel-prev relative z-20 w-14 h-14 rounded-full flex items-center justify-center group border border-slate-700 bg-slate-800 hover:bg-slate-700 transition duration-150 ease-in-out">
              <span className="sr-only">Previous</span>
              <svg className="w-4 h-4 fill-slate-400 transition duration-150 ease-in-out" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
              </svg>
            </button>
            <button className="carousel-next relative z-20 w-14 h-14 rounded-full flex items-center justify-center group border border-slate-700 bg-slate-800 hover:bg-slate-700 transition duration-150 ease-in-out">
              <span className="sr-only">Next</span>
              <svg className="w-4 h-4 fill-slate-400 transition duration-150 ease-in-out" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
              </svg>
            </button>
          </div> */}
        </div>
      </div>
    </section>
  )
}