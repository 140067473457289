export default function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Blocks */}
        <div className="grid sm:grid-cols-12 lg:grid-cols-10 gap-8 py-8 md:py-12 border-t border-slate-800">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-2 lg:max-w-xs">
            <div className="mb-2">
              {/* Logo */}
              <a className="inline-flex" href="/" aria-label="Cruip">
              <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" id="ehddaehg" className="h-20 css-8aabad">
                <defs id="SvgjsDefs1684"></defs>
                <g fill="#fff" id="SvgjsG1686" transform="translate(25,25) scale(0.5)">
                  <path d="M58.623 40.078 l-9.4336 20.039 l-15.059 0 l-0.46875 -16.377 c-2.6074 5.3613 -5.332 10.898 -7.793 16.377 l-15.088 0 l-0.29297 -20.039 l-2.666 0 l-0.43945 -23.936 l15.293 0 l-0.41016 17.314 c2.666 -5.7129 5.4492 -11.572 8.2031 -17.314 l14.092 0 l0 17.314 c2.6367 -5.7129 5.127 -11.572 7.7051 -17.314 l15 0 l-11.309 23.936 l2.666 0 z"></path>
                </g>
              </svg>
              </a>
            </div>
          </div>
          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-xs text-slate-200 font-semibold uppercase underline mb-3">Quick as</h6>
            <ul className="text-sm space-y-2">
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="/login">
                  Sign In
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="/register">
                  Sign Up
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="/dashboard">
                  Dashboard
                </a>
              </li>
            </ul>
          </div>
          {/* 3rd block */}
          {/* <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-xs text-slate-200 font-semibold uppercase underline mb-3">Resources</h6>
            <ul className="text-sm space-y-2">
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  Blog
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  Cheat Sheet
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  Channel Partners
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  Affiliate Program
                </a>
              </li>
            </ul>
          </div> */}
          {/* 4th block */}
          {/* <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-xs text-slate-200 font-semibold uppercase underline mb-3">Projects</h6>
            <ul className="text-sm space-y-2">
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  Session Recording
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  Feature Flags
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  Heatmaps
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  Correlation Analysis
                </a>
              </li>
            </ul>
          </div> */}
          {/* 5th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-xs text-slate-200 font-semibold uppercase underline mb-3">Company</h6>
            <ul className="text-sm space-y-2">
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  About Us
                </a>
              </li>
              <li>
                <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">
                  Our Story
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between pb-4 md:pb-8">
          {/* Social links */}
          {/* <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <a
                className="flex justify-center items-center text-indigo-600 hover:text-slate-300 transition duration-150 ease-in-out"
                href="#0"
                aria-label="Twitter"
              >
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z" />
                </svg>
              </a>
            </li>
            <li className="ml-2">
              <a
                className="flex justify-center items-center text-indigo-600 hover:text-slate-300 transition duration-150 ease-in-out"
                href="#0"
                aria-label="Medium"
              >
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23 8H9a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1Zm-1.708 3.791-.858.823a.251.251 0 0 0-.1.241V18.9a.251.251 0 0 0 .1.241l.838.823v.181h-4.215v-.181l.868-.843c.085-.085.085-.11.085-.241v-4.887l-2.41 6.131h-.329l-2.81-6.13V18.1a.567.567 0 0 0 .156.472l1.129 1.37v.181h-3.2v-.181l1.129-1.37a.547.547 0 0 0 .146-.472v-4.749a.416.416 0 0 0-.138-.351l-1-1.209v-.181H13.8l2.4 5.283 2.122-5.283h2.971l-.001.181Z" />
                </svg>
              </a>
            </li>
            <li className="ml-2">
              <a
                className="flex justify-center items-center text-indigo-600 hover:text-slate-300 transition duration-150 ease-in-out"
                href="#0"
                aria-label="Github"
              >
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                </svg>
              </a>
            </li>
          </ul> */}
          {/* as */}
          <div className="text-sm text-slate-600">
            <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">Terms</a> · <a className="text-slate-500 hover:text-slate-300 transition duration-150 ease-in-out" href="#0">Privacy Policy</a>
          </div>
        </div>
      </div>
    </footer>
  )
}
