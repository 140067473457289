import { useEffect, useState } from 'react';
import './Ticket.css';
import QRCode from "react-qr-code";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { generateTicketSummary } from '../../utils/utils';

const Ticket = () => {
  const [order, setOrder] = useState(undefined);
  let navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getOrder(params.id);
    }
  }, [params])

  const getOrder = (id) => {
    fetch(`${process.env.REACT_APP_API}/api/whoppa/getOrder/${id}`, {})
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
            setOrder(data.content)
        });
      } else {
        toast.error('Something went wrong.')
      }
    })
  }
  
  return (
    <div className="flex justify-center items-center h-dvh bg-gray-100">
      {order && <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow w-full sm:w-9/12">
        <div className="px-4 py-5 sm:px-6">
          <div className='flex justify-between flex-col sm:flex-row sm:items-center'>
            <h1 className='font-bold text-3xl'>{order.event.name}</h1>
            <h1 className='font-bold text-lg sm:text-xl'>{order.event.location}</h1>
          </div>
          <small>{moment(order.event.startDate).format('D/M hh:mm A')} - {moment(order.event.endDate).format('D/M hh:mm A')}</small>
          <p className='text-gray-600'>{order.event.description}</p>
        </div>
        <div className='px-4 py-5 sm:p-6'>
          <div className="w-full flex justify-between items-center">
            <div>
              {generateTicketSummary(order.tickets).split(', ').map((ticket) => (<h1 className='font-bold text-3xl my-5'>{ticket}</h1>))}
            </div>
            <div className="ticket-qr">
              <QRCode value={`TICKETVALUE=${params.id}`}/>
            </div>
          </div>
        </div>
      </div>}
    </div>
  )
  return (
    <div className="tickets-wrapper" onClick={() => navigate('/')}>
      <div className="tickets">
        <h1>Club Lima 6 December<br></br><small>Rijnkaai 2, 2000 Antwerpen, België</small></h1>

        <div className="ticket-qr">
          <QRCode value="https://tickets-taupe.vercel.app/scan"/>
        </div>
      </div>
    </div>
  );
}

export default Ticket;
