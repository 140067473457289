
import { useNavigate, useParams } from 'react-router-dom';
import './MarketerDetail.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Avatar } from '@mui/material';
import { fetchWithCredentials } from '../../utils/utils';
import { EnvelopeIcon, PhoneIcon, TicketIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify';
import NavbarMobile from '../../components/navbar/NavbarMobile';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};
  
const getLast7Days = () => {
  const today = new Date();
  const last7Days = [];

  for (let i = 6; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);

    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = (date.getFullYear()).toString(); // Month is 0-indexed

    last7Days.push({ day, month, year });
  }

  return last7Days;
}

const MarketerDetail = () => {
    const [marketer, setMarketer] = useState(undefined)
    const [birth, setBirth] = useState("")
    const [data, setData] = useState({
      labels: getLast7Days().map(x => `${x.day}-${x.month}`),
      datasets: [
        {
          label: 'Tickets',
          data: [0, 0, 0, 0, 0, 0, 0],
          borderColor: 'rgb(79, 70, 229)',
          backgroundColor: 'rgba(79, 70, 229)',
        },
      ],
  })
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.id) {
          getMarketer(params.id);
        }
    }, [params])

    const getMarketer = (id) => {
        fetchWithCredentials(`/api/whoppa/getMarketer/${id}`, {})
        .then(response => {
          setMarketer(response.content);
          if (response.content.birth) {
              let parts = new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(response.content.birth)).split('/');
              setBirth(`${parts[2]}-${parts[0]}-${parts[1]}`);
          }

          const countItemsByDate = {};

          const originalDates = getLast7Days().map(x => `${x.day}-${x.month}`);
          response.content.tickets?.forEach((obj) => {
              const createdOnDate = new Date(obj.createdOn);
              const day = createdOnDate.getDate().toString().padStart(2, '0'); // Ensure two digits
              const month = (createdOnDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
              const mappedDate = `${day}-${month}`;
              
              // Check if the date is in the originalDates array
              if (originalDates.includes(mappedDate)) {
                  // Increment the count for the date
                  countItemsByDate[mappedDate] = (countItemsByDate[mappedDate] || 0) + 1;
              }
          });

          const countsArray = originalDates.map(date => countItemsByDate[date] || 0);
          handleUpdateData(countsArray);
        })
    }

    const handleUpdateData = (newData) => {
      setData({
          ...data,
          datasets: [{
              ...data.datasets[0],
              data: newData
          }]
      })
  }

    const copyLink = () => {
      navigator.clipboard.writeText(`https://www.whoppatix.com/663b816e16d74d9b8553abb2?ref=${marketer._id}`)
    .then(() => {
      toast.success('Copied to clipboard!')
    })
    .catch(err => {
      toast.error('Unable to copy!')
    });
    }

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                    <div className='flex-grow p-4 sm:p-12'>
                  {marketer && <div>
                    <div>
                      <img className="h-32 w-full object-cover lg:h-48" src="https://source.unsplash.com/random" alt="" />
                    </div>
                    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                      <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                        <div className="flex">
                          <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={marketer.picture} alt="" />
                        </div>
                        <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                          <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
                            <h1 className="truncate text-2xl font-bold text-gray-900">{marketer.name} {marketer.ambassador && <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-insettext-green-700 ring-green-600/20 bg-green-50`}>
                            Ambassador
                        </span>}</h1>
                          </div>
                          <div className="mt-6 flex gap-2 justify-stretch">
                            <div
                              className="inline-flex w-24 h-12 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              <a
                                href={`mailto:${marketer.email}`}
                                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                              >
                                <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                Email
                              </a>
                            </div>
                            <div
                              className="inline-flex w-24 h-12 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              <a
                                href={`tel:${marketer.phone}`}
                                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                              >
                                <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                Call
                              </a>
                            </div>
                            <div
                              className="inline-flex w-24 h-12 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              <span
                                onClick={() => copyLink()}
                                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                              >
                                <TicketIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                Link
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
                        <h1 className="truncate text-2xl font-bold text-gray-900">{marketer.name} {marketer.ambassador && <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-insettext-green-700 ring-green-600/20 bg-green-50`}>
                            Ambassador
                        </span>}</h1>
                      </div>
                    </div>
                    <div className="mt-8 flow-root bg-white rounded-lg ring-1 ring-black ring-opacity-5 shadow">
                        <div className="overflow-x-auto p-4">
                            <p><strong>Birth:</strong> {new Date(marketer.birth).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                            <p><strong>Insta:</strong> {marketer.insta}</p>
                            <p><strong>Email:</strong> {marketer.email}</p>
                            <p><strong>Phone:</strong> {marketer.phone}</p>
                        </div>
                    </div>
                    <div className="mt-8 flow-root bg-white rounded-lg ring-1 ring-black ring-opacity-5 shadow">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 p-4 flex justify-between">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <Bar options={options} data={data} />
                            </div>
                        </div>
                    </div>
                  </div>}
                </div>
                <Footer />
                </div>
            </div>
        </div>
    );
}

export default MarketerDetail;