import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import infoReducer from './redux/infoSlice'
import { persistReducer } from 'redux-persist';
import { thunk } from 'redux-thunk'

const reducers = combineReducers({
  infoReducer          
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return [thunk]
  },
});

export default store;