
import { useNavigate, useParams } from 'react-router-dom';
import './CreateMarketer.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { fetchWithCredentials, parseDateString, postWithCredentials } from '../../utils/utils';
import Dropzone from 'react-dropzone';
import { FaCheck, FaFolder } from 'react-icons/fa';
import Datepicker from "tailwind-datepicker-react"
import NavbarMobile from '../../components/navbar/NavbarMobile';

const options = {
	title: "",
	autoHide: true,
	todayBtn: false,
	clearBtn: true,
	clearBtnText: "Clear",
	maxDate: new Date("2030-01-01"),
	minDate: new Date("1950-01-01"),
	theme: {
		background: "text-white bg-white",
		todayBtn: "",
		clearBtn: "",
		icons: "",
		text: "",
		disabledText: "bg-red-300",
		input: "",
		inputIcon: "",
		selected: "",
	},
	icons: {
		// () => ReactElement | JSX.Element
		prev: () => <span>Previous</span>,
		next: () => <span>Next</span>,
	},
	datepickerClassNames: "top-12",
	defaultDate: new Date(),
	language: "en",
	disabledDates: [],
	weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
	inputNameProp: "date",
	inputIdProp: "date",
	inputPlaceholderProp: "Select Date",
	inputDateFormatProp: {
		day: "numeric",
		month: "long",
		year: "numeric",
        hour: "numeric",
        minute: "numeric"
	}
}

const CreateMarketer = () => {
    const [editMode, setEditMode] = useState(false);
    const [arrayBuffer, setArrayBuffer] = useState(undefined);
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [uploading, setUploading] = useState(false)
    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.id) {
          setEditMode(true);
          getMarketer(params.id);
        }
    }, [params])

    const getMarketer = (id) => {
        fetchWithCredentials(`/api/whoppa/getMarketer/${id}`, {})
        .then(response => {
            const marketer = response.content;
            
            document.getElementById("name").value = marketer.name;
            document.getElementById("insta").value = marketer.insta;
            document.getElementById("phone").value = marketer.phone;
            if (marketer.birth) {
                let parts = new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(marketer.birth)).split('/');
                document.getElementById("birth").value = `${parts[2]}-${parts[0]}-${parts[1]}`;
            }
            document.getElementById("ambassador").value = marketer.ambassador;
            document.getElementById("notes").value = marketer.notes || '';
            document.getElementById("email").value = marketer.email || '';
        })
    }

    const createMarketer = (e) => {
        e.preventDefault();
        const name = document.getElementById("name").value;
        const insta = document.getElementById("insta").value;
        const phone = document.getElementById("phone").value;
        const birth = document.getElementById("birth").value;
        const ambassador = document.getElementById("ambassador").checked;
        const notes = document.getElementById("notes").value;
        const email = document.getElementById("email").value;

        const formData = new FormData();
        let images = [];

        const bar = new Promise((resolve, reject) => {
            if (arrayBuffer) {
                const test = new Blob([arrayBuffer], { type: type });
                images.push(test);
            }
            resolve();
        });

        bar.then(() => {
            images.forEach((image, index) => formData.append(name, image, name))
            formData.append('name', name)
            formData.append('phone', phone)
            formData.append('email', email)
            formData.append('insta', insta)
            formData.append('birth', parseDateString(birth))
            formData.append('ambassador', ambassador)
            formData.append('notes', notes)

            postWithCredentials(`/api/whoppa/${editMode ? ('editMarketer/' + params.id) : 'createMarketer'}`, {
                body: formData
            })
            .then(response => {
                navigate('/dashboard/marketing')
            });
        });
    }
    
    const handleChange = (selectedDate) => {
		console.log(selectedDate)
	}
	const handleClose = (state) => {
		setShow(state)
	}

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                <div className='flex-grow p-4 sm:p-12'>
                    <form>
                    <div className="space-y-6 sm:space-y-8">
                        <div>
                            <h2 className="text-2xl font-semibold leading-6 text-gray-900">Profile</h2>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                This information will be displayed in the marketing page.
                            </p>
                        </div>
                
                        <div>
                        <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Full name
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="name"
                                id="name"
                                autoComplete="name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>
                
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Email address
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Phone number
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                id="phone"
                                name="phone"
                                type="phone"
                                autoComplete="phone"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="insta" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Instagram
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                id="insta"
                                name="insta"
                                type="text"
                                autoComplete="instagram"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="insta" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Birthday
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <Datepicker options={{...options, inputIdProp: 'birth'}} onChange={handleChange} show={show} setShow={handleClose} classNames="block w-full py-1.5 text-gray-900 ring-gray-300 placeholder:text-gray-400 sm:max-w-xs sm:text-sm sm:leading-6" />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Notes
                                </label>
                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    <textarea
                                    id="notes"
                                    name="notes"
                                    rows={3}
                                    className="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={''}
                                    />
                                    <p className="mt-3 text-sm leading-6 text-gray-600">Write some notes about the marketer here.</p>
                                </div>
                            </div>

                            <fieldset>
                        <legend className="sr-only">Ambassador</legend>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
                            <div className="text-sm leading-6 text-gray-900" aria-hidden="true">
                            Ambassador
                            </div>
                            <div className="mt-4 sm:col-span-2 sm:mt-0">
                            <div className="max-w-lg space-y-6">
                                <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                    id="ambassador"
                                    name="ambassador"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="ambassador" className="font-medium text-gray-900">
                                    Ambassador
                                    </label>
                                    <p className="mt-1 text-gray-600">Special member of the promo team.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </fieldset>
                        </div>
                        </div>
                    </div>
                
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => navigate('/dashboard/marketing')}>
                        Cancel
                        </button>
                        <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={(e) => createMarketer(e)}
                        >
                        {editMode ? 'Save' : 'Create'}
                        </button>
                    </div>
                    </form>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default CreateMarketer;