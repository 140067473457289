
import { useNavigate, useParams } from 'react-router-dom';
import './Users.css';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount } from '../../redux/infoSlice';
import { toast } from 'react-toastify';
import { fetchWithCredentials, postWithCredentials } from '../../utils/utils';
import UsersTable from '../../components/users-table/UsersTable';
import NavbarMobile from '../../components/navbar/NavbarMobile';

const Users = () => {
    const account = useSelector((state) => state.infoReducer.account)
    const [createNew, setCreateNew] = useState(false)
    const [newAccount, setNewAccount] = useState({ permissions: { payouts: false } })
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getProfile();
    }, [])

    const getProfile = () => {
        fetchWithCredentials(`/api/whoppa/getProfile/${account._id}`, {})
        .then(response => {
            dispatch(setAccount({
                ...account,
                ...response.content
            }))
        })
    }

    const deleteUser = (id) => {
        postWithCredentials(`/api/whoppa/deleteSubAccount`, {
            body: JSON.stringify({
                id
            })
        })
        .then(response => {
            toast.success('Deleted Sub Account!')
            getProfile()
        });
    }
    
    return (
        <div className="Events company">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                    <div className='flex-grow p-4 sm:p-12'>
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Manage Users</h3>
                            <div className="mt-3 sm:ml-4 sm:mt-0">
                                <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => navigate('/dashboard/users/create')}
                                >
                                Add User
                                </button>
                            </div>
                        </div>
                        <UsersTable users={account.accounts || []} onDelete={deleteUser} />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Users;