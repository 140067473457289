import React from 'react';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Analytics } from '@vercel/analytics/react';

import Landing from './pages/landing/Landing';
import Home from './pages/home/Home';
import NoMatch from './pages/nomatch/NoMatch';
import Dashboard from './pages/dashboard/Dashboard';
import Tables from './pages/tables/Tables';
import Ticket from './pages/ticket/Ticket';
import Scan from './pages/scan/Scan';
import Events from './pages/events/Events';
import CreateEvent from './pages/create-event/CreateEvent';
import CreateUser from './pages/create-user/CreateUser';
import Marketing from './pages/marketing/Marketing';
import Orders from './pages/orders/Orders';
import CreateMarketer from './pages/create-marketer/CreateMarketer';
import MarketerDetail from './pages/marketer-detail/MarketerDetail';
import GuestList from './pages/guestlist/GuestList';
import AddGuestList from './pages/guestlist-add/AddGuestList';
import BlackList from './pages/blacklist/BlackList';
import AddBlackList from './pages/blacklist-add/AddBlackList';
import Finances from './pages/finances/Finances';
import Company from './pages/company/Company';
import VerifyPayment from './pages/verify-payment/VerifyPayment';
import {
  Elements
} from '@stripe/react-stripe-js';

import {loadStripe} from '@stripe/stripe-js';
import Scanner from './pages/scanner/Scanner';
import Register from './pages/register/Register';
import Login from './pages/login/Login';

import store from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import { Provider } from 'react-redux'
import Users from './pages/users/Users';
import Contact from './pages/contact/Contact';
import AuthenticatedRoute from './utils/authenticatedRoute';
let persistor = persistStore(store);

const rootElement = document.getElementById("root");

const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'eur',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const stripePromise = loadStripe("pk_live_51OlHfrLydEfUidHIJzGPmG5Mggot3wk0hrtYCM2I8EUV0YaKtZ70PjAqa6wmpxQ0QHnQJAumaFHMWtqiVdZQKoNH00yoRHQZBX");

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
  <ToastContainer autoClose={600} />
   <Elements stripe={stripePromise} options={options}>
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/:id" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/ticket/:id" element={<Ticket />} />
      <Route path="/scan" element={<Scan />} />
      <Route path="/scanner" element={<Scanner />} />
      <Route path="/verify/:secret" element={<VerifyPayment />} />
      <Route path="/dashboard" element={<AuthenticatedRoute><Dashboard /></AuthenticatedRoute>} />
      <Route path="/dashboard/guestlist" element={<AuthenticatedRoute><GuestList /></AuthenticatedRoute>} />
      <Route path="/dashboard/guestlist/create" element={<AuthenticatedRoute><AddGuestList /></AuthenticatedRoute>} />
      <Route path="/dashboard/blacklist" element={<AuthenticatedRoute><BlackList /></AuthenticatedRoute>} />
      <Route path="/dashboard/blacklist/create" element={<AuthenticatedRoute><AddBlackList /></AuthenticatedRoute>} />
      <Route path="/dashboard/marketing" element={<AuthenticatedRoute><Marketing /></AuthenticatedRoute>} />
      <Route path="/dashboard/marketing/create" element={<AuthenticatedRoute><CreateMarketer /></AuthenticatedRoute>} />
      <Route path="/dashboard/marketing/edit/:id" element={<AuthenticatedRoute><CreateMarketer /></AuthenticatedRoute>} />
      <Route path="/dashboard/marketing/detail/:id" element={<AuthenticatedRoute><MarketerDetail /></AuthenticatedRoute>} />
      <Route path="/dashboard/finances" element={<AuthenticatedRoute><Finances /></AuthenticatedRoute>} />
      <Route path="/dashboard/orders" element={<AuthenticatedRoute><Orders /></AuthenticatedRoute>} />
      <Route path="/dashboard/company" element={<AuthenticatedRoute><Company /></AuthenticatedRoute>} />
      <Route path="/dashboard/users" element={<AuthenticatedRoute><Users /></AuthenticatedRoute>} />
      <Route path="/dashboard/users/create" element={<AuthenticatedRoute><CreateUser /></AuthenticatedRoute>} />
      <Route path="/dashboard/users/edit/:id" element={<AuthenticatedRoute><CreateUser /></AuthenticatedRoute>} />
      <Route path="/dashboard/events" element={<AuthenticatedRoute><Events /></AuthenticatedRoute>} />
      <Route path="/dashboard/events/create" element={<AuthenticatedRoute><CreateEvent /></AuthenticatedRoute>} />
      <Route path="/dashboard/events/edit/:id" element={<AuthenticatedRoute><CreateEvent /></AuthenticatedRoute>} />
      <Route path="/tables" element={<AuthenticatedRoute><Tables /></AuthenticatedRoute>} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
    </Elements>
  </BrowserRouter>
  <Analytics />
  </PersistGate>
  </Provider>,
  rootElement
);
