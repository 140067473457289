import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Pricing() {
  const [annual, setAnnual] = useState(true)
  const navigate = useNavigate();

  return (
    <section className="relative">
      {/* Illustration */}
      <div className="hidden lg:block absolute bottom-0 left-1/2 -translate-x-1/2 -mb-24 pointer-events-none" aria-hidden="true">
        <img src={`${process.env.PUBLIC_URL}/pricing-illustration.svg`} className="max-w-none" alt="Pricing Illustration" />
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-10 pb-12 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-hkgrotesk mb-4">Price breakdown</h2>
            <p className="text-xl text-slate-500">
              In the price table below you will find the price breakdown based on your event paramaters, don't worry there are no hidden fees or extra costs.
            </p>
          </div>
          {/* Pricing tables */}
          <div className="flex flex-col md:flex-row justify-center">
            <div className='flex-1'>
              <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-800 md:border-l border-slate-700 order-1 md:order-none md:text-center">
                <div className="font-hkgrotesk text-lg font-bold  mb-0.5">Standard</div>
              </div>
              <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-800 md:border-l border-slate-700 order-1 md:order-none md:text-center">
                <div className="font-hkgrotesk text-lg font-bold  mb-0.5">1 - 500 Tickets</div>
              </div>
              <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-800 md:border-l border-slate-700 order-1 md:order-none md:text-center">
                <div className="font-hkgrotesk text-lg font-bold  mb-0.5">€1.79 Fee / Ticket</div>
              </div>
              <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-800 md:border-l border-slate-700 order-1 md:order-none md:text-center">
                <div className="font-hkgrotesk text-lg font-bold  mb-0.5">€0.10 Kickback / Ticket</div>
              </div>
              <div className="flex flex-col justify-center p-4 bg-slate-700 bg-opacity-25 md:border-l border-slate-700 order-1 md:order-none">
                {/* TODO NAV: href to register */}
                <a className="btn-sm text-white bg-indigo-600 hover:bg-indigo-700 w-full shadow-sm group whitespace-nowrap" href="/contact">
                  Try Now{' '}

                </a>
              </div>
            </div>
            <div className="mt-5 md:mt-0 flex-1">
              <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-800 md:border-l border-slate-700 order-2 md:order-none md:text-center">
                <div className="font-hkgrotesk text-lg font-bold text-indigo-600 mb-0.5">Enterprise</div>
              </div>
              <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-800 md:border-l border-slate-700 order-1 md:order-none md:text-center">
                <div className="font-hkgrotesk text-lg font-bold  mb-0.5">500+ Tickets</div>
              </div>
              <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-800 md:border-l border-slate-700 order-1 md:order-none md:text-center">
                <div className="font-hkgrotesk text-lg font-bold  mb-0.5">Custom Fee Deal</div>
              </div>
              <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-800 md:border-l border-slate-700 order-1 md:order-none md:text-center">
                <div className="font-hkgrotesk text-lg font-bold  mb-0.5">Custom Kickback Deal</div>
              </div>
              <div className="flex flex-col justify-center p-4 bg-slate-700 bg-opacity-25 md:border-l border-slate-700 order-2 md:order-none">
                {/* <a className="btn-sm text-white bg-indigo-600 hover:bg-indigo-700 w-full shadow-sm group whitespace-nowrap" href="/contact">
                  Contact Us{' '}

                </a> */}
                <button className="btn-sm text-white bg-indigo-600 hover:bg-indigo-700 w-full shadow-sm group whitespace-nowrap" onClick={() => navigate('/contact')}>
                  Contact Us{' '}

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}