import Header from '../../components/landing/header'
import Footer from '../../components/landing/footer'
import Hero from '../../components/landing/hero'
import Testimonials from '../../components/landing/testimonials'
import Features from '../../components/landing/features'
import Pricing from '../../components/landing/pricing'
import Faqs from '../../components/landing/faqs'
import Cta from '../../components/landing/cta'
import './style.css';

export default function Home() {
  return (
      <body className={`font-inter antialiased bg-slate-900 text-slate-200 tracking-tight`}>
        <div className="flex flex-col min-h-screen overflow-hidden">
        <Header />
      
      <main className="grow">

        {/* Illustration */}
        <div className="hidden md:block absolute left-1/2 -translate-x-1/2 pointer-events-none" aria-hidden="true">
          <img src={`${process.env.PUBLIC_URL}/hero-illustration.svg`} className="max-w-none" priority alt="Hero Illustration" />
        </div>

        <Hero />
      <Testimonials />
      <Features />
      <Pricing />
      <Faqs />
      <Cta />

      </main>

      <Footer />
        </div>
      </body>
  )
}