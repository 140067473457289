
import './Finances.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';
import PayoutsTable from '../../components/payouts-table/PayoutsTable';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { WithAuth, fetchWithCredentials } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount, setOrders } from '../../redux/infoSlice';
import { useEffect, useState } from 'react';
import NavbarMobile from '../../components/navbar/NavbarMobile';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};

const getLast12Months = () => {
  const today = new Date();
  const last12Months = [];

  for (let i = 11; i >= 0; i--) {
    const date = new Date(today);
    date.setMonth(today.getMonth() - i);

    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = (date.getFullYear()).toString();

    last12Months.push({ month, year });
  }

  return last12Months;
}

const Finances = () => {
  const account = useSelector((state) => state.infoReducer.account)
    const dispatch = useDispatch();

    const orders = useSelector((state) => state.infoReducer.orders)
    const [data, setData] = useState({
        labels: getLast12Months().map(x => `${x.month}-${x.year}`),
        datasets: [
          {
            label: '€',
            data: [0, 0, 0, 0, 0, 0, 0],
            borderColor: 'rgb(79, 70, 229)',
            backgroundColor: 'rgba(79, 70, 229)',
          },
        ],
    })

  useEffect(() => {
    getProfile();
    getOrders();
  }, [])

  const getProfile = () => {
      fetchWithCredentials(`/api/whoppa/getProfile/${account._id}`, {})
      .then(response => {
          dispatch(setAccount({
              ...account,
              ...response.content
          }))
      })
  }

  const getOrders = () => {
    fetchWithCredentials(`/api/whoppa/getOrders`, {})
    .then(response => {
        dispatch(setOrders(response.content))

        const countItemsByDate = {};

        const originalDates = getLast12Months().map(x => `${x.month}-${x.year}`);
        response.content.forEach((obj) => {
            const createdOnDate = new Date(obj.createdOn);
            const month = (createdOnDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
            const year = createdOnDate.getFullYear().toString();
            const mappedDate = `${month}-${year}`;
            
            // Check if the date is in the originalDates array
            if (originalDates.includes(mappedDate)) {
                // Increment the count for the date
                countItemsByDate[mappedDate] = (countItemsByDate[mappedDate] || 0) + calculateTotalPrice(obj.tickets);
            }
        });

        const countsArray = originalDates.map(date => countItemsByDate[date] || 0);
        handleUpdateData(countsArray);
    })
}

const calculateTotalPrice = (tickets) => {
  let totalPrice = 0;

  tickets.forEach(ticket => {
      totalPrice += ticket.price;
  });

  return totalPrice;
}

const handleUpdateData = (newData) => {
  setData({
      ...data,
      datasets: [{
          ...data.datasets[0],
          data: newData
      }]
  })
}

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                  <div className='flex-grow p-4 sm:p-12'>
                  <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Payouts</h3>
                            <h3 className="text-xl font-semibold leading-6 text-gray-600 ">Balance: €{(account.balance || 0).toFixed(2)}</h3>
                            <div className="mt-3 sm:ml-4 sm:mt-0">
                                <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                Request
                                </button>
                            </div>
                        </div>

                      <PayoutsTable />

                      <div className="mt-8 flow-root bg-white rounded-lg ring-1 ring-black ring-opacity-5 shadow">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 p-4 grid-cols-1 gap-6 sm:grid-cols-2">
                              <div className="inline-block w-full aspect-video sm:w-2/4 py-2 align-middle sm:px-6 lg:px-8">
                                <Bar options={options} data={data} />
                              </div>
                          </div>
                      </div>
                  </div>
                  
                  <Footer />
                </div>
            </div>
        </div>
    );
}

export default WithAuth(Finances, ['payouts']);