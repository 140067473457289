import { useState, useEffect } from 'react';
import './Scanner.css';
import QrScanner from 'qr-scanner'; 
import Scan from '../scan/Scan';
import { toast } from 'react-toastify';
import Menu from './Menu';
import { FaPlus } from 'react-icons/fa';

const Scanner = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [foundCode, setFoundcode] = useState(false);
    const [scanResult, setScanResult] = useState(undefined);
  
    const handleScan = (result) => {
        if (result && !scanResult && !foundCode) {
            setFoundcode(true)
            if (result.data.includes('TICKETVALUE=')) {                
                fetch(`${process.env.REACT_APP_API}/api/whoppa/scanOrder/${result.data.replace('TICKETVALUE=', '')}`, {})
                .then(response => {
                  if (response.status === 200) {
                    response.json().then(data => {
                        setScanResult(data.content)
                    });
                  } else {
                    toast.error('Something went wrong.')
                  }
                })
            }
        }
    };

    useEffect(() => {
        const videoElem = document.getElementById('scanner');
        if (videoElem) {
            const qrScanner = new QrScanner(
                videoElem,
                result => handleScan(result),
                {
                    onDecodeError: error => console.log(error),
                    highlightScanRegion: true,
                    highlightCodeOutline: true,
                }
            );
            qrScanner.start().then(() => {
                qrScanner.setCamera('environment')
            });
        }
    }, [scanResult]);

    const closeMenu = () => {
        setScanResult(undefined);
        setFoundcode(false);
    }

    return (
        <>
            {!scanResult && (
                <div className="scanner overflow-hidden" id="video-container">
                    <Menu
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                    />
                    <video id="scanner" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}></video>
                    <FaPlus className="absolute top-6 right-6 cursor-pointer" size={32} onClick={() => setShowMenu(true)} />
                </div>
            )}
            {scanResult && <Scan reset={() => closeMenu()} scanResult={scanResult} />}
        </>
    );
};

export default Scanner;