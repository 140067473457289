
import { useNavigate } from 'react-router-dom';
import './Orders.css';
import OrdersTable from '../../components/orders-table/OrdersTable';
import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { setOrders } from '../../redux/infoSlice';
import { fetchWithCredentials } from '../../utils/utils';
import NavbarMobile from '../../components/navbar/NavbarMobile';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
};

const getLast7Days = () => {
    const today = new Date();
    const last7Days = [];
  
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
  
      const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
      const year = (date.getFullYear()).toString(); // Month is 0-indexed
  
      last7Days.push({ day, month, year });
    }
  
    return last7Days;
}

const Orders = () => {
    const orders = useSelector((state) => state.infoReducer.orders)
    const [data, setData] = useState({
        labels: getLast7Days().map(x => `${x.day}-${x.month}`),
        datasets: [
          {
            label: 'Tickets',
            data: [0, 0, 0, 0, 0, 0, 0],
            borderColor: 'rgb(79, 70, 229)',
            backgroundColor: 'rgba(79, 70, 229)',
          },
        ],
    })
    const [search, setSearch] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getOrders()
    }, [])

    const getOrders = () => {
        fetchWithCredentials(`/api/whoppa/getOrders`, {})
        .then(response => {
            dispatch(setOrders(response.content))

            const countItemsByDate = {};

            const originalDates = getLast7Days().map(x => `${x.day}-${x.month}`);
            response.content.forEach((obj) => {
                const createdOnDate = new Date(obj.createdOn);
                const day = createdOnDate.getDate().toString().padStart(2, '0'); // Ensure two digits
                const month = (createdOnDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
                const mappedDate = `${day}-${month}`;
                
                // Check if the date is in the originalDates array
                if (originalDates.includes(mappedDate)) {
                    // Increment the count for the date
                    countItemsByDate[mappedDate] = (countItemsByDate[mappedDate] || 0) + obj.tickets.length;
                }
            });

            const countsArray = originalDates.map(date => countItemsByDate[date] || 0);
            handleUpdateData(countsArray);
        })
    }

    const handleUpdateData = (newData) => {
        setData({
            ...data,
            datasets: [{
                ...data.datasets[0],
                data: newData
            }]
        })
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    return (
        <div className="Orders">
            <div className="OrdersWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                    <div className='flex-grow p-4 sm:p-12'>
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">Orders</h3>
                        </div>
                        <div className="mt-8 flow-root bg-white rounded-lg ring-1 ring-black ring-opacity-5 shadow">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <Bar options={options} data={data} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex gap-3 sm:items-start sm:pt-6">
                            <label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Search
                            </label>
                            <div className="mt-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="search"
                                    id="search"
                                    autoComplete=""
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    value={search}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>

                        {orders && orders.length > 0 && <OrdersTable orders={search ? orders.filter(order => {
                            return (
                            order.firstName.toLowerCase().includes(search.toLowerCase()) ||
                            order.lastName.toLowerCase().includes(search.toLowerCase())
                            );
                        }) : orders} refresh={getOrders} />}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Orders;