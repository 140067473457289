'use client';

import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import IconClose from '../../components/iconClose/iconClose';
import { generateTicketSummary } from '../../utils/utils';
import moment from 'moment';

const Menu = ({
    showMenu,
    setShowMenu,
    id,
}) => {
    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState([]);

    const getOrders = () => {
        fetch(`${process.env.REACT_APP_API}/api/whoppa/getScanned`, {})
        .then(response => response.json())
        .then(response => {
            setOrders(response.content.orders)
            setTotalOrders(response.content.totalOrders)
        })
    };

    useEffect(() => {
        getOrders();
    }, [showMenu]);

    return (
        <Transition.Root show={showMenu} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={setShowMenu}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="acity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity max-h-[100dvh]" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex h-[100dvh] items-center justify-center p-0 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="w-full h-[100dvh] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-between rounded-full">
                                        <h4 className="font-bold sm:text-lg md:text-xl lg:text-2xl">
                                            Overview
                                        </h4>
                                        <p>
                                            {orders.reduce((accumulator, currentItem) => {
                                                return accumulator + currentItem.tickets.length;
                                            }, 0)}
                                            /
                                            {totalOrders.reduce((accumulator, currentItem) => {
                                                return accumulator + currentItem.tickets.length;
                                            }, 0)}
                                        </p>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setShowMenu(false)
                                            }
                                        >
                                            <IconClose
                                                className="stroke-black"
                                                size={20}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5 flex flex-col justify-center border-t border-black">
                                        {orders && orders.map(order => <div className="border-b border-black w-full py-3">
                                            <p className='font-bold'>{order.firstName} {order.lastName}</p>
                                            {generateTicketSummary(order.tickets)}
                                            <p className="text-xs">Scanned: {moment(order.checkedInOn).format('D/M hh:mm A')}</p>
                                        </div>)}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default Menu;
